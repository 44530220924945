import { HttpHeaders } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { TranslatePipe } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import {
  MotorRequestInterface,
  ProductInterface,
  QuotationInterface,
  VehicleListingInterface,
} from "src/app/share/interface/motor.interface";
import { AlertService } from "src/app/share/service/alert.service";
import { PartnerService } from "src/app/share/service/partner.service";

@Component({
  selector: "app-edit-quotation",
  templateUrl: "./edit-quotation.component.html",
  styleUrls: ["./edit-quotation.component.scss"],
  providers: [TranslatePipe],
})
export class EditQuotationComponent {
  partnerCode: string = "ps";

  vehicleList: VehicleListingInterface[] = [];
  limit: number = 20;
  offset: number = 1;
  isCompleted: boolean = false;

  searchChange: Subject<void> = new Subject<void>();
  searchText: string;

  selectVehicle: boolean = false;

  isLoading: boolean = false;

  originalMotorProductList: ProductInterface[] = [];
  motorProductList: ProductInterface[] = [];
  filterMotorProductList: ProductInterface[] = [];
  comMotorProductList: ProductInterface[];
  tpftMotorProductList: ProductInterface[];
  allMotorProductList: ProductInterface[];

  frmGroup: FormGroup;

  firstInit: boolean = true;

  currentData: Record<string, any> = {};

  isGrabSingleQuotation: boolean = false;
  bundleSite: boolean = false;

  private sumInsuredList = this.data.quotationList
    .filter((x) => x.status !== "New" && x.status !== "Rejected")
    .map((x) => x.sumInsured);

  constructor(
    private motorDataDataService: MotorDataDataService,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private partnerService: PartnerService,
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<EditQuotationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
      request: MotorRequestInterface;
      quotationList: QuotationInterface[];
      grabSingleQuotation: boolean;
      bundleSite: boolean;
      headers: HttpHeaders;
    },
    private translatePipe: TranslatePipe,
  ) {
    this.getPartnerCode();

    this.searchText = data.request.vehicle;
    this.isGrabSingleQuotation = data.grabSingleQuotation;
    this.bundleSite = data.bundleSite;

    this.initForm();
    this.getVehicleListing();
    this.getAllProduct();

    this.searchChange.pipe(debounceTime(500)).subscribe((x) => {
      this.offset = 1;
      this.vehicleList = [];
      this.getVehicleListing();
    });

    this.frmGroup.controls.insuranceType.valueChanges.subscribe((value) => {
      this.frmGroup.controls.selectedMotorProductList.setValue([]);
      // this.getProduct(value);
      this.filterMotorProductSelection();
    });
  }

  get showOtherInsurerInput() {
    if (
      this.partnerCode !== "ktmb" &&
      !(this.partnerCode === "ps" && this.data?.request?.eHailingUsed)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getPartnerCode() {
    this.partnerCode = this.partnerService.getPartnerCode();
    !this.partnerCode ? (this.partnerCode = "ps") : null;
  }

  private initForm() {
    this.frmGroup = new FormGroup(
      {
        vehicleId: new FormControl(null),
        typeOfSum: new FormControl(this.data.request.typeOfSum ?? "MV", [
          Validators.required,
        ]),
        insuranceType: new FormControl(this.data.request.insuranceType, [
          Validators.required,
        ]),
        sumInsured: new FormControl(Math.min(...this.sumInsuredList)),
        selectedMotorProductList: new FormControl([]),
      },
      { validators: sumInsuredOrProductSelectedValidator },
    );

    // ## Specific requirement: For grab TFPT include generali
    this.frmGroup.controls.insuranceType.valueChanges.subscribe((x) => {
      if (x == "COM") {
        this.motorProductList = this.originalMotorProductList.filter(
          (x: any) => x.insuranceType == "COM" || x.insuranceType == null,
        );
      }
      if (x == "TPFT") {
        this.motorProductList = this.originalMotorProductList.filter(
          (x: any) => x.insuranceType == "TPFT" || x.insuranceType == null,
        );
      }
    });
  }

  private getVehicleListing() {
    this.isLoading = true;
    this.motorDataDataService
      .getVehicleListing(
        this.limit,
        this.offset,
        this.searchText,
        this.data.request.make,
      )
      .subscribe({
        next: (x) => {
          this.isCompleted = x.length < this.limit;
          this.vehicleList = this.vehicleList.concat(x);
          this.isLoading = false;
          let vehicleId: string;
          if (!this.firstInit) {
            return;
          }
          this.firstInit = false;

          if (this.vehicleList.length != 0) {
            vehicleId = this.vehicleList[0].id;
            this.frmGroup.controls.vehicleId.setValue(vehicleId);
          }

          this.currentData = {
            vehicleId,
            typeOfSum: this.data.request.typeOfSum ?? "MV",
            insuranceType: this.data.request.insuranceType,
            sumInsured: Math.min(...this.sumInsuredList),
            selectedMotorProductList: this.data.quotationList.map(
              (x) => x.productId,
            ),
          };
        },
      });
  }

  private getAllProduct() {
    this.isLoading = true;
    this.motorDataDataService.getAllProduct(this.data.id, this.data.headers).subscribe({
      next: (x: any) => {
        this.isLoading = false;
        this.originalMotorProductList = x;
        // Default insuranceType is COM, so show COM dropdown list
        this.motorProductList = this.originalMotorProductList.filter(
          (x: any) => x.insuranceType == "COM" || x.insuranceType == null,
        );
        this.filterMotorProductSelection();
      },
      error: (err) => {
        this.isLoading = false;
      },
    });
  }

  clearVehicle() {
    event.stopImmediatePropagation();
    this.frmGroup.controls.vehicleId.setValue(null);
  }
  getSelectedProductList() {
    return this.frmGroup.value.selectedMotorProductList;
  }

  //#region  MotorProductSelection
  filterMotorProductSelection(searchValue: string = null) {
    if (searchValue) {
      let txt = searchValue.toLowerCase().trim();
      this.filterMotorProductList = this.motorProductList.filter((x) =>
        x.name.toLowerCase().includes(txt),
      );
    } else {
      this.filterMotorProductList = Object.assign([], this.motorProductList);
    }
  }

  isSelectedAllMotorProduct() {
    return this.getSelectedProductList().length == this.motorProductList.length;
  }

  isIndeterminateMotorProduct() {
    if (this.isSelectedAllMotorProduct()) return false;
    return this.getSelectedProductList().length > 0;
  }

  toggleSelectAllMotorProduct(val: boolean) {
    this.frmGroup.controls.selectedMotorProductList.setValue([]);
    if (val) {
      this.frmGroup.controls.selectedMotorProductList.setValue(
        this.motorProductList.map((y) => y.id),
      );
      this.frmGroup.controls.selectedMotorProductList.markAsDirty();
    }
  }

  getMotorProductDisplay() {
    if (this.motorProductList.length == 0) return null;
    if (this.getSelectedProductList().length == 1)
      return this.motorProductList.find(
        (x) => x.id == this.getSelectedProductList()[0],
      ).name;
    else if (this.isSelectedAllMotorProduct())
      return this.translatePipe.transform("common.allInsurer");
    else
      return `${
        this.getSelectedProductList().length
      } ${this.translatePipe.transform("common.insurerSelected")}`;
  }

  resetMotorProduct() {
    event.stopImmediatePropagation();
    this.toggleSelectAllMotorProduct(true);
  }
  //#endregion

  filterVehicle() {
    if (!this.selectVehicle) this.searchChange.next();
    else this.selectVehicle = false;
  }

  getNextBatch() {
    if (!this.isCompleted) {
      this.offset++;
      this.getVehicleListing();
    }
  }

  isDisabledSubmit(): boolean {
    if (this.frmGroup.invalid || this.frmGroup.pristine) {
      return true;
    }
    // return this.isSelectionAndCurrentEqual() && !hasSelectedMotorProductList;
    return this.isSelectionAndCurrentEqual();
  }

  save() {
    if (this.frmGroup.invalid) return;

    this.ngxSpinnerService.show();
    let value = Object.assign({}, this.frmGroup.value);
    if (!value.selectedMotorProductList?.length) {
      value.selectedMotorProductList = this.data.quotationList.map(
        (x) => x.productId,
      );
    }
    this.motorDataDataService.editRequest(this.data.id, value, this.data.headers).subscribe({
      next: () => {
        this.ngxSpinnerService.hide();
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.ngxSpinnerService.hide();
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  getSumInsuredConfig(): ISumInsuredConfig {
    let min = Math.min(...this.sumInsuredList);
    let max = +(min * 1.1).toFixed(2);
    let sumInsured = min;

    // Adjust sum insured if Chubb is selected (for Affiliates only)
    if (this.partnerCode !== "ps") {
      const ChubbId = this.motorProductList.find((x) => x.code === 'Chubb')?.id ?? '';
      const isChubbSelected = this.getSelectedProductList().includes(ChubbId);
      if (isChubbSelected) {
        const isChubbFound = !!this.data.quotationList.find((x) => x.code === 'Chubb');
        if (isChubbFound) sumInsured = this.data.quotationList.find((x) => x.code === 'Chubb')?.sumInsured;
        max = +(sumInsured * 1.2).toFixed(2);
        min = +(sumInsured * 0.9).toFixed(2);
      }
    }

    return {
      min,
      max,
      minError: "common.sumInsuredMinError",
      maxError: "common.sumInsuredMaxError",
      sumInsured
    };
  }

  private isSelectionAndCurrentEqual(): boolean {
    this.currentData = {
      typeOfSum: this.data.request.typeOfSum ?? "MV",
      insuranceType: this.data.request.insuranceType,
      sumInsured: Math.min(...this.sumInsuredList),
    };

    const isVehicleIdEmpty = !this.frmGroup.value.vehicleId;
    // const isTypeOfSumEqual =
    //   this.frmGroup.value.typeOfSum === this.currentData.typeOfSum;
    const isInsuranceTypeEqual =
      this.frmGroup.value.insuranceType === this.currentData.insuranceType;
    const isSumInsuredEqual =
      +this.frmGroup.value.sumInsured === this.currentData.sumInsured;
    const isOtherInsurerEmpty =
      this.frmGroup.value.selectedMotorProductList.length == 0;

    return (
      isVehicleIdEmpty &&
      isInsuranceTypeEqual &&
      isSumInsuredEqual &&
      isOtherInsurerEmpty
    );
  }
}

function sumInsuredOrProductSelectedValidator(
  formGroup: FormGroup,
): ValidationErrors | null {
  const { sumInsured, selectedMotorProductList } = formGroup.value;
  const isSumInsuredEmpty = !sumInsured;
  const isSelectedMotorProductListEmpty =
    !selectedMotorProductList || selectedMotorProductList.length === 0;

  if (isSumInsuredEmpty && isSelectedMotorProductListEmpty) {
    return { requiredSumInsuredOrProduct: true };
  }
  return null;
}

interface ISumInsuredConfig {
  min: number;
  max: number;
  minError: string;
  maxError: string;
  sumInsured: number;
}
