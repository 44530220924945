import { Injectable } from "@angular/core";
import * as Forge from 'node-forge';
import { environment } from "../../../environments/environment";
import { CryptographyDataService } from "../data-service/cryptography.data.service";

@Injectable({
	providedIn: "root",
})
export class RSAService {

    encryptedValue: string;

	constructor(private cryptographyDataService: CryptographyDataService) {}

    async encryptWithPublicKey(valueToEncrypt: string): Promise<string> {

        if (environment.IsKeyManagementServiceEnabled) {
            try {
                const rsaPublicKeyBase64: string = await this.cryptographyDataService.getAsymmetricPublicKey().toPromise();
                const publicKey = atob(rsaPublicKeyBase64);
                const rsa = Forge.pki.publicKeyFromPem(publicKey);

                //REF: https://stackoverflow.com/questions/53834903/javas-rsa-ecb-oaepwithsha-256andmgf1padding-equivalent-in-node-js
                this.encryptedValue = rsa.encrypt(valueToEncrypt.toString(), 'RSA-OAEP', {
                    md: Forge.md.sha256.create(),
                    mgf1: {
                      md: Forge.md.sha256.create()
                    }});
            } catch (error) {
                window.location.href = "/";
            }
        } else {
            const publicKey = atob(environment.rsaPublicKeyBase64);
            const rsa = Forge.pki.publicKeyFromPem(publicKey);
            this.encryptedValue = rsa.encrypt(valueToEncrypt.toString());
        }
        
        return window.btoa(this.encryptedValue);
    }
}
