import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { BeforeExpiredDialogComponent } from "src/app/dashboard/before-expired-dialog/before-expired-dialog.component";
import { MotorQuotationDataService } from "src/app/share/data-service/motor/motor-quotation.data.service";
import { MotorQuotationInterface } from "src/app/share/interface/motor-quotation.interface";
import { AlertService } from "src/app/share/service/alert.service";
import { LoadingOverlayService } from "src/app/share/service/loading-overlay.service";
import {
  ILoadingWords,
  LOADING_KEYWORDS_LIST,
  LOADING_WORDINGS_LIST,
} from "./loading-wordings-list";
import { SubmitSuccessDialogComponent } from "src/app/dashboard/submit-success-dialog/submit-success-dialog.component";
import { SubmitFailDialogComponent } from "src/app/dashboard/submit-fail-dialog/submit-fail-dialog.component";
import { SubmitQuotationResponseInterface } from "src/app/share/interface/motor.interface";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";

@Component({
  selector: "app-loading-quotation",
  templateUrl: "./loading-quotation.component.html",
  styleUrls: ["./loading-quotation.component.scss"],
})
export class LoadingQuotationComponent implements OnInit {
  countKeyword: number = 1;
  countWord: number = 1;
  countTimeLeft: number = 14;
  keyword: string = "BEST";
  keywordList: string[] = LOADING_KEYWORDS_LIST;

  wordingList: ILoadingWords[] = LOADING_WORDINGS_LIST;
  firstWording: string = this.wordingList[0].first;
  secondWording: string = this.wordingList[0].second;

  timeLeftState: number = 15;
  stopTimerState: boolean = false;
  finishedTimerState: boolean = false;
  beforeExpiredState: boolean = false;
  onOperation: boolean;

  @Input() inputData: MotorQuotationInterface;

  constructor(
    private motorQuotationDataService: MotorQuotationDataService,
    private dialog: MatDialog,
    private motorDataService: MotorDataDataService,
    private alertService: AlertService,
  ) {}

  loopWordingsList() {
    let index = this.countWord % this.wordingList.length;

    this.firstWording = this.wordingList[index]?.first;
    this.secondWording = this.wordingList[index]?.second;

    if (index == this.wordingList.length - 1) {
      setTimeout(() => {
        this.stopTimerState = true;
        // Don't show high demands page if its a before expired case
        if (!this.beforeExpiredState) {
          this.finishedTimerState = true;
        }
      }, 15000);
    } else {
      this.countWord++;
    }
  }

  loopKeywordsList() {
    let index = this.countKeyword % this.keywordList.length;

    this.keyword = this.keywordList[index];

    if (!this.stopTimerState) {
      this.countKeyword++;
    }
  }

  loopNumberCountdown() {
    var timeLeft = 15;
    let index = this.countTimeLeft % timeLeft;

    this.timeLeftState = index;

    if (this.countTimeLeft !== 0 && !this.stopTimerState) {
      this.countTimeLeft--;
    } else {
      this.countTimeLeft = 14;
    }
  }

  ngOnInit(): void {
    this.runTimerLogic();
    this.submitQuotation();
  }

  runTimerLogic() {
    let keywordInterval = setInterval(() => this.loopKeywordsList(), 3000);
    let wordingInterval = setInterval(() => {
      this.loopWordingsList();
    }, 15000);
    let loopNumberCountdown = setInterval(
      () => this.loopNumberCountdown(),
      1000,
    );
    if (this.stopTimerState) {
      clearInterval(wordingInterval);
      clearInterval(keywordInterval);
      clearInterval(loopNumberCountdown);
    }
  }

  private async isOnOperation(): Promise<boolean> {
    const response = await this.motorDataService
      .checkIsOfficeHour()
      .toPromise();
    return response === "YES";
  }

  backToHome() {
    window.location.href = "/";
  }

  async submitQuotation() {
    this.onOperation = await this.isOnOperation();

    if (this.inputData) {
      (await this.motorQuotationDataService.submit(this.inputData)).subscribe({
        next: (x: SubmitQuotationResponseInterface) => {
          this.stopTimerState = true;
          if (x.isSuccess) {
            if (x.isInstantQuote && x.redirect_url) {
              window.location.href = x.redirect_url;
              return;
            }
            if (!x.isInstantQuote) {
              const dialogRef = this.dialog.open(SubmitSuccessDialogComponent, {
                panelClass: "success-dialog",
                data: { expiryDate: x.data, customerInput: this.inputData },
              });
              dialogRef.afterClosed().subscribe(() => {
                if (this.onOperation && x.redirect_url) {
                  window.location.href = x.redirect_url;
                  return;
                } else {
                  this.backToHome();
                }
              });
            }
          } else {
            this.finishedTimerState = false;
            this.beforeExpiredState = true;

            let dialogRef: any;
            if (x.isVehicleOverage) {
              dialogRef = this.dialog.open(SubmitFailDialogComponent, {
                data: {
                  isWithEHailing: x.isEhailing
                },
                panelClass: "fail-dialog",
              });
            } else {
              dialogRef = this.dialog.open(BeforeExpiredDialogComponent, {
                panelClass: "",
                data: { expiryDate: x.data },
              });
            }

            dialogRef.afterClosed().subscribe((x) => {
              this.backToHome();
            });
          }
        },
        error: (err) => {
          this.stopTimerState = true;
          this.finishedTimerState = true;
          this.alertService.openSnackBar("Please contact policy street.");
        },
      });
    }
  }
}
