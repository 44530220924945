<div class="dialog-container">
  <div class="dialog-header">
    <img src="../../../../assets/image/request-status-icon.png" />
    <div>Request in Progress</div>
  </div>

  <div class="dialog-body">
    <div [innerHTML]="description"></div>
    <div>{{ "dialog.duplicateDesc" | translate }}</div>
  </div>

  <div class="dialog-footer">
    <button (click)="onClose()">
      {{ "dialog.close" | translate }}
    </button>
  </div>
</div>
