import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, finalize, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { EndorsementService } from 'src/app/d2c/data-service/endorsement.service';
import { EndorsementPaymentDetailInterface, EndorsementPaymentFormInterface } from './endorsement.interface';
import { ScreenSizeService } from 'src/app/share/service/screen-size.service';
import { PAYMENT_OPTION_FP, PAYMENT_OPTION_RAZER, PAYMENT_OPTION_VISA } from "src/app/share/constants/payment-options";
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/share/service/alert.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-endorsement-payment',
  templateUrl: './endorsement-payment.component.html',
  styleUrls: ['./endorsement-payment.component.scss']
})
export class EndorsementPaymentComponent implements OnInit, OnDestroy {

  paymentFormGroup: FormGroup;
  endorsementData$: Observable<EndorsementPaymentDetailInterface>;
  id: string;
  requestData: any;
  headers: HttpHeaders;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private endorsementService: EndorsementService, 
    public screenSizeService: ScreenSizeService,
    public activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // Listen for window resize events
    this.screenSizeService.notifyResize();
    window.addEventListener("resize", () =>
      this.screenSizeService.notifyResize(),
    );

    // Prepare encoded id and encryption key for http services
    this.id = encodeURIComponent(this.activatedRoute.snapshot.params.id);
    const key = decodeURIComponent(this.activatedRoute.snapshot.queryParams.key);
    this.headers = new HttpHeaders({ 'X-Encrypted-AES-Key-RouteParam': key });
    
    // Initialize form group and get endorsement data
    this.initPaymentFormGroup();
    this.getEndorsementData();
  }

  ngOnDestroy(): void {
    // Unsubscribe from observables to prevent memory leaks
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initPaymentFormGroup() {
    this.paymentFormGroup = new FormGroup({
      paymentType: new FormControl(PAYMENT_OPTION_FP, [Validators.required]),
      paymentProvider: new FormControl(PAYMENT_OPTION_RAZER, [Validators.required]),
      paymentChannel: new FormControl(PAYMENT_OPTION_VISA, []),
      ewalletType: new FormControl('', []),
      bank: new FormControl('', []),
      installmentMonth: new FormControl(0, []),
    })
  }

  getEndorsementData() {
    this.endorsementData$ = this.endorsementService.getEndorsementPaymentDetail(this.id, this.headers)
      .pipe(
        catchError(error => {
          return throwError('Failed to fetch endorsement data');
        }),
        // Unsubscribe when component is destroyed
        takeUntil(this.unsubscribe$),
        
        shareReplay() 
      );
  }

  getTax(endorsementData: EndorsementPaymentDetailInterface): number {
    return endorsementData?.total * endorsementData?.serviceChargeRate;
  }

  getTotalPrice(endorsementData: EndorsementPaymentDetailInterface): number {
    return endorsementData?.total + this.getTax(endorsementData);
  }

  getIdentityNoLabel() {
		if (this.requestData?.isMalaysian && !this.requestData?.isCompany)
			return "common.icNo";
		else if (!this.requestData?.isMalaysian) return "common.passport";
		return "common.businessRegistrationNo";
	}

	getNameLabel() {
		if (this.requestData?.isMalaysian && !this.requestData?.isCompany)
			return "common.namePerIc";
		else if (!this.requestData?.isMalaysian) return "common.namePerPassport";
		return "common.companyName";
	}

  handleSubmit() {
    // Handle form submission
    this.ngxSpinnerService.show();
    let endorsementFormData: EndorsementPaymentFormInterface = {
      paymentChannel: this.paymentFormGroup?.value?.paymentChannel,  
      paymentProvider: this.paymentFormGroup?.value?.paymentProvider,
      paymentType: this.paymentFormGroup?.value?.paymentType
    }

    this.endorsementService.generateEndorsementPayment(this.id, endorsementFormData, this.headers)
      .pipe(
        tap((x: any) => {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              id: x.id,
            },
          };
          this.router.navigate(["/payment"], navigationExtras);
        }),
        catchError(error => {
          this.alertService.openSnackBar(error.error);
          return throwError('Failed to fetch endorsement data');
        }),
        finalize(() =>  this.ngxSpinnerService.hide()),
        // Unsubscribe when component is destroyed
        takeUntil(this.unsubscribe$)
      )
    .subscribe();
  }
}