// Angular Core
import { Component, HostListener, ViewChild, OnInit } from "@angular/core";
import { FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { forkJoin, Observable, tap } from "rxjs";
// Angular Material
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { MatSelectChange } from "@angular/material/select";
// Services
import { AlertService } from "../../../share/service/alert.service";
import { MasterDataDataService } from "../../../share/data-service/master-data.data.service";
import {
  CheckDuplicateResponse,
  MotorQuotationDataService,
} from "../../../share/data-service/motor/motor-quotation.data.service";
import { PartnerService } from "src/app/share/service/partner.service";
// Interfaces
import { MotorQuotationInterface } from "../../../share/interface/motor-quotation.interface";
import { DropdownItem } from "../../../share/interface/common.interface";
// Components
import { SubmitSuccessDialogComponent } from "src/app/dashboard/submit-success-dialog/submit-success-dialog.component";
import { SubmitFailDialogComponent } from "src/app/dashboard/submit-fail-dialog/submit-fail-dialog.component";
import { BeforeExpiredDialogComponent } from "../../../share/component/before-expired-dialog/before-expired-dialog.component";
// Utilities
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
import { SwiperComponent } from "swiper/angular";
import { Overlay } from "@angular/cdk/overlay";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { ValidatorFunction } from "../../../share/function/validator.function";
import { INSURED_TYPE } from "src/app/share/constants/common.types";
import { RequestStatusDialogComponent } from "src/app/d2c/ui/request-status-dialog/request-status-dialog.component";
import * as moment from "moment";

interface Banner {
  lang: string;
  src: string;
  show: boolean;
  alt: string;
}
SwiperCore.use([Pagination, Autoplay, Navigation]);
@Component({
  selector: "app-affiliates-landing",
  templateUrl: "./affiliates-landing.component.html",
  styleUrls: ["./affiliates-landing.component.scss"],
})
export class AffiliateslandingComponent implements OnInit {
  partnerCode: string;
  bannerView: boolean = false;
  currentLang: string;

  partnerBannerViewList = ["carsome", "wapcar", "grab", "ktmb"];

  @ViewChild("stepper") stepper: MatStepper;
  vehicleFormGroup: FormGroup;
  custFormGroup: FormGroup;
  @ViewChild("vehicleForm") vehicleForm: NgForm;
  @ViewChild("custForm") custForm: NgForm;
  @ViewChild("swiper", { static: false }) swiper!: SwiperComponent;
  @HostListener("window:resize", [])
  onResize() {
    var width = window.innerWidth;
    this.mobileView = width < 960;
  }

  countryList: string[] = [];
  filterCountryList: string[] = [];
  isCompany: boolean = false;
  isMalaysian: boolean = true;
  pdpaAgreement: boolean = false;
  clickedNext: boolean = false;
  eHailingChecked: boolean = false;
  mobileView: boolean = false;
  banners: Banner[];
  loadedBanners: number = 0;

  idType: Number = 1;
  utmCampaign: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
  maxDob: Date = new Date();
  contactInfo: string;
  successGifType: string;

  quotationSubmitted: boolean = false;
  quotationInputData: MotorQuotationInterface;
  validPostcodes: string[] = [];
  mileagePlans: DropdownItem[] = [];
  idTypeList: DropdownItem[] = [];

  typeOfInsuranceList = [
    // { id: "TP", name: "common.thirdPartyOnlyIns" },
    { id: "TPFT", name: "common.TPFT" },
    { id: "COM", name: "common.COM" },
  ];

  showWhatsappBtn: Boolean = true;
  hideWhatsappBtnList: string[] = ["wapcar", "carsome", "carlist", "ktmb"];

  showMileagePlans: Boolean = false;
  showMileagePlansList: string[] = ["ktmb"];

  showAgreeOrMarketValueBtn: Boolean = false;
  showAgreeOrMarketValueBtnList: string[] = ["carsome", "ktmb"];

  showEHailingOption: Boolean = true;
  hideEHailingOptionList: string[] = ["ktmb"];

  typeOfSumOption = INSURED_TYPE;

  constructor(
    gtmService: GoogleTagManagerService,
    private ngxSpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private masterDataDataService: MasterDataDataService,
    private motorQuotationDataService: MotorQuotationDataService,
    private partnerService: PartnerService,
    private alertService: AlertService,
    private dialog: MatDialog,
    public overlay: Overlay,
    private location: Location,
  ) {
    gtmService.pushTag({
      event: "car_lp",
    });
    this.getParams();
    this.initForm();
    this.buildIdTypeList();
  }

  ngOnInit() {
    if (window.screen.width < 960) {
      this.mobileView = true;
    }
  }

  private getParams() {
    let snapshotParam = this.activatedRoute.snapshot.queryParams;
    let paramsPartnerCode = snapshotParam.partnerCode;
    let lang: string = snapshotParam.lang;
    this.partnerCode = this.partnerService.getPartnerCode();
    this.initPartnerBanners();
    if (this.hideWhatsappBtnList.includes(this.partnerCode)) {
      this.showWhatsappBtn = false;
    }

    if (this.showMileagePlansList.includes(this.partnerCode)) {
      this.showMileagePlans = true;
    }

    if (this.partnerBannerViewList.includes(this.partnerCode)) {
      this.bannerView = true;
    }

    if (this.showAgreeOrMarketValueBtnList.includes(this.partnerCode)) {
      this.showAgreeOrMarketValueBtn = true;
    }

    if (this.hideEHailingOptionList.includes(this.partnerCode)) {
      this.showEHailingOption = false;
    }

    !this.partnerCode ? (window.location.href = "/") : null;

    if (lang) this.translate.use(lang);
    this.utmSource = snapshotParam.utm_source;
    this.utmMedium = snapshotParam.utm_medium;
    this.utmCampaign = snapshotParam.utm_campaign;
    this.utmContent = snapshotParam.utm_content;

    if (!this.utmSource) {
      let searchEngineList = ["google", "yahoo", "bing"];
      let referrer = document.referrer;
      if (
        referrer != window.location.href ||
        referrer.includes("policystreet.com")
      ) {
        let searchEngine = searchEngineList.find((x) =>
          referrer.toLowerCase().includes(x),
        );
        if (searchEngine) {
          this.utmSource = searchEngine;
          this.utmMedium = "organic";
        } else {
          if (!referrer) this.utmSource = "direct";
          else {
            let url = new URL(referrer);
            this.utmSource = url.hostname;
            this.utmMedium = "referral";
          }
        }
      } else {
        this.utmSource = "direct";
      }
    }

    // if coming from affiliates with domain names, remove route name
    if (!paramsPartnerCode) {
      this.removeUrlRouteName();
    }
  }

  private initForm() {
    this.vehicleFormGroup = new FormGroup({
      insuranceType: new FormControl("COM", Validators.required),
      carPlate: new FormControl(null, Validators.required),
      postcode: new FormControl(null),
      insuredType: new FormControl(null, Validators.required),
      includeRoadTax: new FormControl(false),
      eHailingUsed: new FormControl(false),
      mileagePlan: new FormControl(null),

      // Only for PS D2C
      psIdentityType: new FormControl("Malaysian", Validators.required),
      psIdentityNo: new FormControl(null),
      psPolicIdentityNo: new FormControl(null),
    });

    this.custFormGroup = new FormGroup({
      identityNo: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      policIdentityNo: new FormControl(null),
      nationality: new FormControl(null, Validators.required),
      dob: new FormControl(),
      gender: new FormControl(null),
      maritalStatus: new FormControl(null, Validators.required),
      contactMethod: new FormControl(null),
      mobileNo: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),

      // Only for PS D2C
      psPostCode: new FormControl(null),
    });

    this.vehicleFormGroup.controls.insuranceType.setValue("COM");
    this.vehicleFormGroup.controls.insuredType.setValue("MV");

    this.custFormGroup.controls.maritalStatus.setValue("Single");
    this.custFormGroup.controls.contactMethod.setValue("Email");

    if (this.partnerCode !== "ps") {
      this.setMalaysian();
      this.vehicleFormGroup.controls.postcode.setValidators([
        Validators.required,
        (control: FormControl) => this.postcodeValidator(control),
      ]);
      this.vehicleFormGroup.controls.postcode.updateValueAndValidity();

      if (this.showMileagePlans) {
        this.vehicleFormGroup.controls.mileagePlan.setValidators([
          Validators.required,
        ]);
        this.vehicleFormGroup.controls.mileagePlan.updateValueAndValidity();
      }
      if (this.partnerCode === "ktmb") {
        this.vehicleFormGroup.controls.insuranceType.disable();
      }
    } else {
      // Validations for d2c
      this.setPSMalaysian();
      this.setPSStep2Validation();
    }

    // Get dob and gender on Affiliate
    this.custFormGroup.controls.identityNo.valueChanges.subscribe((value) => {
      if (
        this.custFormGroup.controls.identityNo.valid &&
        this.isMalaysian &&
        !this.isCompany
      ) {
        this.getGenderAndDate(value);
      }
    });

    // set dob and gender on PS
    this.vehicleFormGroup.controls.psIdentityNo.valueChanges.subscribe(
      (value) => {
        if (
          this.vehicleFormGroup.controls.psIdentityNo.valid &&
          this.isMalaysian &&
          !this.isCompany
        ) {
          this.getGenderAndDate(value);
        }
      },
    );

    // set validations for PS ID types
    this.vehicleFormGroup.controls.psIdentityType.valueChanges.subscribe(
      (value) => {
        if (value == "Police/Army") {
          this.setPSPoliceArmy();
        }
        if (value == "Malaysian") {
          this.setPSMalaysian();
        } else if (value == "Company") {
          this.setPSIsCompany();
        } else if (value == "Foreigner") {
          this.setPSNonMalaysian();
        }
      },
    );

    this.fetchMasterData();
  }

  private postcodeValidator(control: FormControl) {
    const value = control.value;

    if (value != null && value.length === 5)
      return this.validPostcodes.includes(value) ? null : { customError: true };
    else return { customError: true };
  }

  private removeUrlRouteName(): void {
    const currentUrl = this.location.path();
    const updatedUrl = currentUrl.replace("/affiliates", "");

    this.location.replaceState(updatedUrl);
  }

  private getGenderAndDate(value) {
    let date = moment(value.substring(0, 6), "YYMMDD");
    if (moment().isBefore(date)) {
      date.subtract(100, "year");
    }
    let lastChar = value.substring(11);
    this.custFormGroup.controls.dob.setValue(date.toDate());
    let gender = lastChar % 2 ? "M" : "F";
    this.custFormGroup.controls.gender.setValue(gender);
  }

  private setPSStep2Validation() {
    this.custFormGroup.controls.identityNo.setValidators([]);
    this.custFormGroup.controls.identityNo.updateValueAndValidity();

    this.custFormGroup.controls.psPostCode.setValidators([
      Validators.required,
      (control: FormControl) => this.postcodeValidator(control),
    ]);
    this.custFormGroup.controls.psPostCode.updateValueAndValidity();
  }

  private setNonCompanyValidation() {
    this.custFormGroup.controls.dob.setValidators([Validators.required]);
    this.custFormGroup.controls.gender.setValidators([Validators.required]);
    this.custFormGroup.controls.maritalStatus.setValidators([
      Validators.required,
    ]);

    this.custFormGroup.controls.dob.updateValueAndValidity();
    this.custFormGroup.controls.gender.updateValueAndValidity();
    this.custFormGroup.controls.maritalStatus.updateValueAndValidity();
  }

  private setCompanyValidation() {
    this.custFormGroup.controls.dob.setValidators([]);
    this.custFormGroup.controls.gender.setValidators([]);
    this.custFormGroup.controls.maritalStatus.setValidators([]);
    this.custFormGroup.controls.nationality.setValidators([]);

    this.custFormGroup.controls.dob.updateValueAndValidity();
    this.custFormGroup.controls.gender.updateValueAndValidity();
    this.custFormGroup.controls.maritalStatus.updateValueAndValidity();
    this.custFormGroup.controls.nationality.updateValueAndValidity();
  }

  private getCountryList() {
    if (this.countryList.length != 0) {
      this.filterCountry();
      return;
    }
    this.masterDataDataService.getCountrySelection().subscribe({
      next: (x) => {
        this.countryList = x;
        this.filterCountry();
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  private resetForm() {
    this.pdpaAgreement = false;
    this.vehicleFormGroup.reset();
    // this.vehicleForm.resetForm();
    this.custFormGroup.reset();
    // this.custForm.resetForm();

    this.custFormGroup.controls.mobileNo.setValue(" ");
    this.custFormGroup.controls.mobileNo.updateValueAndValidity();
    this.initForm();
    this.stepper.reset();
  }

  private setMalaysian() {
    this.isCompany = false;
    this.isMalaysian = true;
    this.custFormGroup.controls.identityNo.setValue(null);
    this.custFormGroup.controls.identityNo.setValidators([
      Validators.required,
      ValidatorFunction.nricValidator(),
    ]);
    this.custFormGroup.controls.dob.disable();
    this.custFormGroup.controls.gender.disable();
    this.custFormGroup.controls.identityNo.updateValueAndValidity();
    this.custFormGroup.controls.nationality.setValidators([]);
    this.custFormGroup.controls.nationality.updateValueAndValidity();

    this.setNonCompanyValidation();
  }

  private setPSMalaysian() {
    this.isCompany = false;
    this.isMalaysian = true;

    this.vehicleFormGroup.controls.psIdentityNo.setValidators([
      Validators.required,
      ValidatorFunction.nricValidator(),
    ]);
    this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();
    this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([]);
    this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
    this.custFormGroup.controls.nationality.setValidators([]);
    this.custFormGroup.controls.nationality.updateValueAndValidity();

    this.setNonCompanyValidation();
  }

  private setNonMalaysian() {
    this.isCompany = false;
    this.isMalaysian = false;
    this.getCountryList();
    this.custFormGroup.controls.identityNo.setValidators([Validators.required]);
    this.custFormGroup.controls.nationality.setValidators([
      Validators.required,
    ]);
    this.custFormGroup.controls.dob.enable();
    this.custFormGroup.controls.gender.enable();
    this.custFormGroup.controls.nationality.updateValueAndValidity();
    this.custFormGroup.controls.identityNo.updateValueAndValidity();

    this.setNonCompanyValidation();
  }

  private setPSNonMalaysian() {
    this.isCompany = false;
    this.isMalaysian = false;
    this.getCountryList();

    this.custFormGroup.controls.nationality.setValidators([
      Validators.required,
    ]);
    this.custFormGroup.controls.nationality.updateValueAndValidity();
    this.vehicleFormGroup.controls.psIdentityNo.setValidators([
      Validators.required,
    ]);
    this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();
    this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([]);
    this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
    this.setNonCompanyValidation();
  }

  private setPSPoliceArmy() {
    this.isCompany = false;
    this.isMalaysian = true;

    this.vehicleFormGroup.controls.psIdentityNo.setValue(null);
    this.vehicleFormGroup.controls.psIdentityNo.setValidators([
      Validators.required,
      ValidatorFunction.nricValidator(),
    ]);
    this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();

    this.custFormGroup.controls.nationality.setValidators([]);
    this.custFormGroup.controls.nationality.updateValueAndValidity();

    this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([
      Validators.required,
    ]);
    this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
  }

  private setIsCompany() {
    this.isCompany = true;
    this.isMalaysian = true;
    this.custFormGroup.controls.identityNo.setValidators([Validators.required]);
    this.custFormGroup.controls.identityNo.updateValueAndValidity();
    this.custFormGroup.controls.name.setValidators([]);
    this.custFormGroup.controls.name.updateValueAndValidity();

    this.setCompanyValidation();
  }

  private setPSIsCompany() {
    this.isCompany = true;
    this.isMalaysian = true;

    this.vehicleFormGroup.controls.psIdentityNo.setValidators([
      Validators.required,
    ]);
    this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();
    this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([]);
    this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
    this.setCompanyValidation();
  }

  idTypeChange(e: MatSelectChange) {
    let value = e.value;
    switch (value) {
      case 1:
        this.setMalaysian();
        break;
      case 2:
        this.setNonMalaysian();
        break;
      case 3:
        this.setIsCompany();
        break;
    }
  }

  getIdentityNoLabel() {
    if (this.isMalaysian && !this.isCompany) return "common.icNoLabel";
    else if (!this.isMalaysian) return "common.passportLabel";
    return "common.businessRegistrationNoLabel";
  }

  getNameLabel() {
    if (this.isMalaysian && !this.isCompany) return "common.namePerIc";
    else if (!this.isMalaysian) return "common.namePerPassport";
    return "common.companyName";
  }

  getIdentityNoMasking() {
    if (this.isMalaysian && !this.isCompany) return "000000000000";
    return "";
  }

  filterCountry(val: string = null) {
    if (val) {
      let txt = val.trim().toLowerCase();
      this.filterCountryList = this.countryList.filter((x) =>
        x.trim().toLowerCase().startsWith(txt),
      );
    } else this.filterCountryList = Object.assign([], this.countryList);
  }

  eHailingUsedChange(e: boolean) {
    this.eHailingChecked = !this.eHailingChecked;
    if (e) {
      this.vehicleFormGroup.controls.includeRoadTax.setValue(false);
      this.vehicleFormGroup.controls.includeRoadTax.disable();
    } else {
      this.vehicleFormGroup.controls.includeRoadTax.enable();
    }
    this.vehicleFormGroup.controls.includeRoadTax.updateValueAndValidity();
  }

  async submit(): Promise<void> {
    if (!this.vehicleFormGroup.valid || !this.custFormGroup.valid) return;
    let vehicleValue = this.vehicleFormGroup.getRawValue();
    let custValue = this.custFormGroup.getRawValue();
    const d2c = this.partnerCode == "ps";
    this.successGifType = custValue.contactMethod;
    custValue.contactMethod == "Mobile"
      ? (this.contactInfo = custValue.mobileNo)
      : (this.contactInfo = custValue.email);

    let contactMethod =
      custValue.contactMethod == "Mobile"
        ? "Mobile (Whatsapp)"
        : custValue.contactMethod;

    let input: MotorQuotationInterface = {
      insuranceType: vehicleValue.insuranceType,
      vehicleNo: vehicleValue.carPlate,
      typeofSum: vehicleValue.insuredType,
      includeRoadTax: vehicleValue.includeRoadTax,
      eHailingUsed: vehicleValue.eHailingUsed,
      isMalaysian: this.isMalaysian,
      isCompany: this.isCompany,
      identityNo: d2c ? vehicleValue.psIdentityNo : custValue.identityNo,
      postcode: d2c ? custValue.psPostCode : vehicleValue.postcode,
      policIdentityNo: d2c
        ? vehicleValue.psPolicIdentityNo
        : custValue.policIdentityNo,
      preferredChannel: contactMethod,
      name: custValue.name,
      nationality: custValue.nationality,
      dob: custValue.dob,
      gender: custValue.gender,
      maritalStatus: custValue.maritalStatus,
      mobileNo: custValue.mobileNo,
      email: custValue.email,
      language: this.translate.currentLang,
      utmSouce: this.utmSource,
      utmMedium: this.utmMedium,
      utmCampaign: this.utmCampaign,
      utmContent: this.utmContent,
      isRenewal: false,
      motorLinkId: null,
      ...(vehicleValue.mileagePlan
        ? { motorMileagePlanId: vehicleValue.mileagePlan }
        : {}),
    };

    const isDuplicateQuote = await this.checkDuplicateQuote(input);
    if (isDuplicateQuote) return;

    // 1) TEMP: turn of IQ for grab and carsome affiliate
    // 2) If agreed value type: No IQ
    if (
      this.noIQAffiliates ||
      input.typeofSum === this.typeOfSumOption.agreedValue
    ) {
      this.quotationSubmitted = false;
      this.ngxSpinnerService.show();
      (await this.motorQuotationDataService.submit(input)).subscribe({
        next: (x) => {
          this.ngxSpinnerService.hide();

          if (x.isSuccess) {
            this.handleSuccessSubmitQuotation(x.data, input);
          } else {
            if (x.isVehicleOverage) {
              this.dialog.open(SubmitFailDialogComponent, {
                data: {
                  isAffiliate: true,
                  isWithEHailing: x.isEhailing,
                },
                panelClass: "fail-dialog",
              });
            } else {
              this.dialog.open(BeforeExpiredDialogComponent, {
                data: { expiryDate: x.data },
              });
            }
          }

          this.resetForm();
        },
        error: (err) => {
          this.ngxSpinnerService.hide();
          this.alertService.openSnackBar("Please contact policy street.");
        },
      });
    } else {
      this.quotationSubmitted = true;
    }

    this.quotationInputData = input;
  }

  async checkDuplicateQuote(
    request: MotorQuotationInterface,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.motorQuotationDataService.checkDuplicateRequest(request).subscribe({
        next: (x: CheckDuplicateResponse) => {
          if (x.isExist === "Y") {
            this.dialog.open(RequestStatusDialogComponent, {
              panelClass: "no-padding-border-radius-dialog",
              data: {
                content: request.vehicleNo,
              },
            });
            resolve(true);
          }

          resolve(false);
        },
        error: (_error: any) => {
          resolve(true);
        },
      });
    });
  }

  whatsppRedirect() {
    const whatsappNumber = 60182822320;
    const entityName = "Policystreet";
    let whatsappContent = "";
    let lang = this.translate.currentLang == "en" ? "en" : "ms";
    whatsappContent =
      lang == "ms"
        ? `Hai ${entityName}, saya ingin tahu dengan lebih lanjut mengenai cukai jalan dan insurans kenderaan`
        : `Hi ${entityName}, I would like to know more about road tax and car insurance`;

    let url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
      whatsappContent,
    )}&source=&data=&app_absent=&lang=${lang}`;

    window.open(url, "_blank");
  }

  checkCss() {
    this.clickedNext = true;
  }

  countLoadedBanners() {
    this.loadedBanners++;

    if (this.loadedBanners > 1) {
      this.swiper.swiperRef.setGrabCursor();
    }
  }

  private buildIdTypeList(): void {
    this.idTypeList = [
      { id: 1, description: "common.icNo" },
      { id: 2, description: "common.foreigner" },
      { id: 3, description: "common.company" },
    ];

    if (this.partnerCode === "ktmb") {
      this.idTypeList = [
        { id: 1, description: "common.icNo" },
        { id: 2, description: "common.foreigner" },
      ];
    }
  }

  private fetchMasterData(): void {
    const obs$: Observable<any>[] = [this.selectAllPostcodes$()];

    if (this.showMileagePlans) {
      obs$.push(this.getMotorMileagePlans$());
    }

    forkJoin(obs$).subscribe({
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  private selectAllPostcodes$(): Observable<string[]> {
    return this.masterDataDataService.selectAllPostcodes().pipe(
      tap((postcodes) => {
        this.validPostcodes = postcodes;
      }),
    );
  }

  private getMotorMileagePlans$(): Observable<DropdownItem[]> {
    return this.masterDataDataService.getMotorMileagePlans().pipe(
      tap((mileagePlans) => {
        this.mileagePlans = mileagePlans;
      }),
    );
  }

  private handleSuccessSubmitQuotation(
    expiryDate: string,
    customerInput: MotorQuotationInterface,
  ): void {
    const dialogRef = this.dialog.open(SubmitSuccessDialogComponent, {
      panelClass: "success-dialog",
      data: { expiryDate, customerInput },
    });
    dialogRef.afterClosed().subscribe(() => this.backToHome());
  }

  backToHome() {
    window.location.href = "/";
  }

  private get noIQAffiliates() {
    const noIQAffiliatesList = ["carsome", "grab", "ktmb"];
    return noIQAffiliatesList.includes(this.partnerCode);
  }

  private initPartnerBanners() {
    this.banners = [
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_1-en.png`,
        show: true,
        alt: "",
      },
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_2-en.png`,
        show: true,
        alt: "",
      },
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_3-en.png`,
        show: true,
        alt: "",
      },
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_4-en.png`,
        show: true,
        alt: "",
      },
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_5-en.png`,
        show: true,
        alt: "",
      },
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_6-en.png`,
        show: true,
        alt: "",
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_1-bm.png`,
        show: true,
        alt: "",
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_2-bm.png`,
        show: true,
        alt: "",
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_3-bm.png`,
        show: true,
        alt: "",
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_4-bm.png`,
        show: true,
        alt: "",
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_5-bm.png`,
        show: true,
        alt: "",
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_6-bm.png`,
        show: true,
        alt: "",
      },
    ];
  }
}
