import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-request-status-dialog",
  templateUrl: "./request-status-dialog.component.html",
  styleUrls: ["./request-status-dialog.component.css"],
})
export class RequestStatusDialogComponent implements OnInit {
  description: string;

  constructor(
    public translate: TranslateService,
    private dialogRef: MatDialogRef<RequestStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      content: string;
    },
  ) {}

  ngOnInit(): void {
    this.description = this.translate
      .instant("dialog.duplicateFound")
      .replace("{1}", this.data.content.toUpperCase());
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
