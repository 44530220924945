import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: "root",
})
export class AESService {
	private key: string = '';
    
	constructor() {}

    aesKey(): string {
        this.key = this.generateRandomCryptoKey(16);
        return this.key;
    }

    generateRandomCryptoKey(byteLength: number): string {
        return CryptoJS.lib.WordArray.random(byteLength);
    }
    
    encrypt(text: any): any {
		const iv = this.generateRandomCryptoKey(16); // Generates a 128-bit (16-byte) IV
        const encrypted = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(text), 
            CryptoJS.enc.Utf8.parse(this.key), 
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

        // Combine the IV and the encrypted text into a single string
        const encryptedWithIv = iv.toString() + ':' + encrypted.toString();

        return encryptedWithIv;
    }
}
