import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, KeyValueDiffers } from "@angular/core";
import { environment } from "../../../environments/environment";
import { API_DATA } from "../static/api-data";
import { AESService } from "src/app/share/service/aes.service";
import { RSAService } from "src/app/share/service/rsa.service";
import { EncryptedPayload } from "src/app/share/interface/common.interface";

@Injectable({
	providedIn: "root",
})
export class MotorBundleService {
	private basePath: string = `${environment.url}/api/Motor/`;

	constructor(private http: HttpClient,
		private aesService: AESService, 
		private rsaService: RSAService
	) {}

	getAllBundleData() {
		return API_DATA;
	}

	getMotorBundle(id: string, quotationId: string) {
		return this.http.get(
			`${this.basePath}GetMotorBundle/${id}?quotationId=${quotationId}`
		);
	}

	getMotorBundleAddOn(id: string, quotationId: string, headers?: HttpHeaders) {
		return this.http.get(
			`${this.basePath}GetMotorBundleAddOn/${id}?quotationId=${quotationId}`, { headers }
		);
	}

	async generateBundlePayment(id: string, values: any, headers?: HttpHeaders){
		const aesKeyValue = this.aesService.aesKey();
    	const encAESKey = await this.rsaService.encryptWithPublicKey(aesKeyValue);
		const encJson = this.aesService.encrypt(JSON.stringify(values));

		const payload: EncryptedPayload = {
			data: encJson,
			key: encAESKey
		};

		return this.http.post(
			`${this.basePath}GenerateBundlePayment/${id}`, payload, { headers }
		);
	}
	
	renewalSubmission(id: string, values: any){
		return this.http.post(
			`${this.basePath}RenewalSubmission/${id}`, values
		);
	}
}
